import * as _ from 'lodash';
import { DataMaskStateWithId } from '@superset-ui/core';
import { HundredXState } from './types';

export const SET_SAVED_VIEW_DISPLAY_NAME = 'SET_SAVED_VIEW_DISPLAY_NAME';
export const SET_HAS_UNSAVED_CHANGES = 'SET_HAS_UNSAVED_CHANGES';
export const SET_CURRENT_SAVED_VIEW_FOR_COMPARISON =
  'SET_CURRENT_SAVED_VIEW_FOR_COMPARISON';
export const SET_SAVED_VIEW_ID = 'SET_SAVED_VIEW_ID';
export const SET_CURRENT_SAVED_VIEW_DETAILS = 'SET_CURRENT_SAVED_VIEW_DETAILS';
export const SET_CURRENT_USER_HX_ID = 'SET_CURRENT_USER_HX_ID';
export const SET_GLOBAL_FILTERS_ENABLED = 'SET_GLOBAL_FILTERS_ENABLED';
export const SET_GLOBAL_FILTERS = 'SET_GLOBAL_FILTERS';
export const SET_DASHBOARD_LABEL = 'SET_DASHBOARD_LABEL';
export const SET_REALTIME_FILTERS = 'SET_REALTIME_FILTERS';

export interface SetSavedViewDisplayNameAction {
  type: typeof SET_SAVED_VIEW_DISPLAY_NAME;
  savedViewDisplayName: string;
}

export interface SetHasUnsavedChangesAction {
  type: typeof SET_HAS_UNSAVED_CHANGES;
  hasUnsavedChanges: boolean;
}

export interface SetCurrentSavedViewForComparisonAction {
  type: typeof SET_CURRENT_SAVED_VIEW_FOR_COMPARISON;
  currentSavedViewForComparison: any;
}

export interface SetSavedViewIdAction {
  type: typeof SET_SAVED_VIEW_ID;
  savedViewId: number;
}

export interface SetCurrentSavedViewDetailsAction {
  type: typeof SET_CURRENT_SAVED_VIEW_DETAILS;
  currentSavedViewDetails: any;
}

export interface SetCurrentUserHxIdAction {
  type: typeof SET_CURRENT_USER_HX_ID;
  currentUserHxId: number;
}

export interface SetGlobalFiltersEnabledAction {
  type: typeof SET_GLOBAL_FILTERS_ENABLED;
  globalFiltersEnabled: boolean;
}

export interface SetGlobalFiltersAction {
  type: typeof SET_GLOBAL_FILTERS;
  globalFilters: any;
}
export interface SetDashboardLabelAction {
  type: typeof SET_DASHBOARD_LABEL;
  dashboardLabel: string;
}

export interface SetRealtimeFiltersAction {
  type: typeof SET_REALTIME_FILTERS;
  realtimeFilters: DataMaskStateWithId;
}

export const setSavedViewDisplayName = (
  savedViewDisplayName: string,
): SetSavedViewDisplayNameAction => ({
  type: SET_SAVED_VIEW_DISPLAY_NAME,
  savedViewDisplayName,
});

export const setHasUnsavedChanges = (
  hasUnsavedChanges: boolean,
): SetHasUnsavedChangesAction => ({
  type: SET_HAS_UNSAVED_CHANGES,
  hasUnsavedChanges,
});

export const setCurrentSavedViewForComparison = (
  currentSavedViewForComparison: any,
): SetCurrentSavedViewForComparisonAction => ({
  type: SET_CURRENT_SAVED_VIEW_FOR_COMPARISON,
  currentSavedViewForComparison,
});

export const setCurrentSavedViewDetails = (
  currentSavedViewDetails: any,
): SetCurrentSavedViewDetailsAction => ({
  type: SET_CURRENT_SAVED_VIEW_DETAILS,
  currentSavedViewDetails,
});

export const setSavedViewId = (savedViewId: number): SetSavedViewIdAction => ({
  type: SET_SAVED_VIEW_ID,
  savedViewId,
});

export const setCurrentUserHxId = (
  currentUserHxId: number,
): SetCurrentUserHxIdAction => ({
  type: SET_CURRENT_USER_HX_ID,
  currentUserHxId,
});

export const setGlobalFiltersEnabled = (
  globalFiltersEnabled: boolean,
): SetGlobalFiltersEnabledAction => ({
  type: SET_GLOBAL_FILTERS_ENABLED,
  globalFiltersEnabled,
});

export const setGlobalFilters = (
  globalFilters: any,
): SetGlobalFiltersAction => ({
  type: SET_GLOBAL_FILTERS,
  globalFilters,
});

export const setDashboardLabel = (
  dashboardLabel: string,
): SetDashboardLabelAction => ({
  type: SET_DASHBOARD_LABEL,
  dashboardLabel,
});

export const setRealtimeFilters = (
  realtimeFilters: DataMaskStateWithId,
): SetRealtimeFiltersAction => ({
  type: SET_REALTIME_FILTERS,
  realtimeFilters,
});

const initialHundredXState: HundredXState = {
  savedViewDisplayName: '',
  hasUnsavedChanges: false,
  currentSavedViewForComparison: {},
  savedViewId: 0,
  currentSavedViewDetails: {},
  currentUserHxId: 0,
  globalFiltersEnabled: true,
  globalFilters: {},
  dashboardLabel: '',
  realtimeFilters: {},
};

export const hxStateReducer = (
  state = initialHundredXState,
  action:
    | SetSavedViewDisplayNameAction
    | SetHasUnsavedChangesAction
    | SetCurrentSavedViewForComparisonAction
    | SetSavedViewIdAction
    | SetCurrentSavedViewDetailsAction
    | SetCurrentUserHxIdAction
    | SetGlobalFiltersEnabledAction
    | SetGlobalFiltersAction
    | SetDashboardLabelAction
    | SetRealtimeFiltersAction,
): HundredXState => {
  switch (action.type) {
    case SET_SAVED_VIEW_DISPLAY_NAME:
      return {
        ...state,
        savedViewDisplayName: action.savedViewDisplayName || '',
      };
    case SET_HAS_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.hasUnsavedChanges,
      };
    case SET_CURRENT_SAVED_VIEW_FOR_COMPARISON:
      return {
        ...state,
        currentSavedViewForComparison: action.currentSavedViewForComparison,
      };
    case SET_SAVED_VIEW_ID:
      return {
        ...state,
        savedViewId: action.savedViewId,
      };
    case SET_CURRENT_SAVED_VIEW_DETAILS:
      return {
        ...state,
        currentSavedViewDetails: action.currentSavedViewDetails,
      };
    case SET_CURRENT_USER_HX_ID:
      return {
        ...state,
        currentUserHxId: action.currentUserHxId,
      };
    case SET_GLOBAL_FILTERS_ENABLED:
      return {
        ...state,
        globalFiltersEnabled: action.globalFiltersEnabled,
      };
    case SET_GLOBAL_FILTERS:
      return {
        ...state,
        globalFilters: action.globalFilters,
      };
    case SET_DASHBOARD_LABEL:
      return {
        ...state,
        dashboardLabel: action.dashboardLabel,
      };

    case SET_REALTIME_FILTERS: {
      return {
        ...state,
        realtimeFilters: action.realtimeFilters,
      };
    }
    default:
      return state;
  }
};

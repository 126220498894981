import React, { ReactNode } from 'react';
import { useTheme } from '@superset-ui/core';

import Icons from 'src/components/Icons';
import { ErrorLevel, ErrorSource } from './types';

interface ErrorAlertProps {
  ErrorAlertDiv: any;
  LeftSideContent: any;
  ErrorModal: any;
  body: ReactNode;
  copyText?: string;
  level: ErrorLevel;
  source?: ErrorSource;
  title: ReactNode;
  description?: string;
}

interface WarningProps {
  [key: string]: any;
}

const friendlyBody = 'Something went wrong. Please try refreshing.';

const WarnToConsole = ({ label, ...extras }: WarningProps) => {
  console.warn('MODAL ERROR:', label, {
    ...extras,
  });
};

export function FriendlyErrorAlert({
  ErrorAlertDiv,
  LeftSideContent,
  body,
  copyText,
  level = 'error',
  source = 'dashboard',
  title,
  description,
}: ErrorAlertProps) {
  const theme = useTheme();
  const iconColor = theme.colors[level].base;

  const FireWarning = () =>
    WarnToConsole({
      label: copyText,
      title,
      body,
      description,
      source,
    });

  return (
    <ErrorAlertDiv level={level} role="alert">
      <div className="top-row">
        <LeftSideContent>
          {level === 'error' ? (
            <Icons.ErrorSolid
              className="icon"
              iconColor={iconColor}
              onClick={FireWarning}
            />
          ) : (
            <Icons.WarningSolid
              className="icon"
              iconColor={iconColor}
              onClick={FireWarning}
            />
          )}
          <strong>{friendlyBody}</strong>
        </LeftSideContent>
      </div>
    </ErrorAlertDiv>
  );
}

interface BasicErrorAlertProps {
  StyledContainer: any;
  StyledContent: any;
  StyledTitle: any;
  title: string;
  body: string;
  level: ErrorLevel;
}

export function FriendlyBasicErrorAlert({
  StyledContainer,
  StyledContent,
  StyledTitle,
  body,
  level = 'error',
  title,
}: BasicErrorAlertProps) {
  const theme = useTheme();
  const iconColor = theme.colors[level].base;

  return (
    <StyledContainer level={level} role="alert">
      {level === 'error' ? (
        <Icons.ErrorSolid
          iconColor={iconColor}
          onClick={() => {
            console.warn(`ERROR: ${title}`, { level, body });
          }}
        />
      ) : (
        <Icons.WarningSolid
          iconColor={iconColor}
          onClick={() => {
            console.warn(`ERROR: ${title}`, { level, body });
          }}
        />
      )}
      <StyledContent>
        <StyledTitle>{title}</StyledTitle>
        <p>{friendlyBody}</p>
      </StyledContent>
    </StyledContainer>
  );
}
